import getData from "@/__main__/get-data.mjs";
import noopModel from "@/data-models/no-op.mjs";
import ValorantLatestPlayerStats from "@/data-models/valorant-latest-player-stats.mjs";
import ValorantPlayerMapStats from "@/data-models/valorant-player-map-stats.mjs";
import ValorantPlayerMatchWinHistoryModel from "@/data-models/valorant-player-match-win-history.mjs";
import ValorantPlayerStats from "@/data-models/valorant-player-stats.mjs";
import * as API from "@/game-val/api.mjs";
import fetchConstants from "@/game-val/fetch-constants-data.mjs";
import fetchLiveMatch from "@/game-val/fetch-live-match.mjs";
import { getExpiryTime, separateNameTag } from "@/game-val/utils.mjs";
import { getPlatformPath } from "@/game-val/utils/console.mjs";
import fetchProfile, {
  getAuthorizationHeaders,
} from "@/game-val/valorant-fetch-profile.mjs";
import { devWarn } from "@/util/dev.mjs";

const fetchLivePageData = async ([nameTag]) => {
  const { name, tag } = separateNameTag(nameTag);
  const player = await fetchProfile(name, tag);
  if (!player || !player.valorantProfile?.region) {
    devWarn("Mandatory info is missing from profile data", player);
    return;
  }

  const headers = await getAuthorizationHeaders();
  const [content] = await fetchConstants();

  const puuid = player.puuid;
  const region = player.valorantProfile.region;
  const latestActId = content.acts?.find((act) => act.endedAt === null)?.uuid;
  const liveMatch = await fetchLiveMatch(player);
  const platformPath = getPlatformPath(false); // PC only for live
  if (!liveMatch) return null;
  const shouldShadow = API.shouldShadow();
  const promises = [];

  const queue = liveMatch.queue;

  const playerMapStatsPayload = {
    puuid,
    actUuid: latestActId,
    region,
    queue,
  };

  const getPlayerMapStats = getData(
    API.getMapStats(playerMapStatsPayload),
    ValorantPlayerMapStats,
    ["val", "playerMapStats", puuid, latestActId, platformPath, queue],
    { headers },
  );
  promises.push(getPlayerMapStats);

  const latestPlayerStatsPayload = {
    puuid,
    region,
    queue,
  };

  const getLatestPlayerStats = getData(
    API.getLatestPlayerStats(latestPlayerStatsPayload),
    ValorantLatestPlayerStats,
    ["val", "latestPlayerStats", puuid, queue],
    { headers },
  );
  promises.push(getLatestPlayerStats);

  const getPlayerStatsPayload = {
    puuid,
    actUuid: latestActId,
    region,
  };

  const getPlayerStats = getData(
    API.getPlayerStats(getPlayerStatsPayload),
    ValorantPlayerStats,
    ["val", "playerActStats", puuid, latestActId, platformPath],
    { headers },
  );

  if (shouldShadow) {
    getData(API.getMapStatsV2(playerMapStatsPayload), noopModel, undefined, {
      headers,
    });

    getData(
      API.getLatestPlayerStatsV2(latestPlayerStatsPayload),
      noopModel,
      undefined,
      { headers },
    );

    getData(API.getPlayerStatsV2(getPlayerStatsPayload), noopModel, undefined, {
      headers,
    });
  }

  promises.push(getPlayerStats);

  const playerProfiles =
    liveMatch.players?.map(({ gameName, tagLine }) => {
      return fetchProfile(gameName, tagLine).then((profile) => {
        if (!profile || !profile.valorantProfile || profile instanceof Error)
          return;

        const getMatchWinHistoryPayload = {
          puuid: profile.puuid,
          actUuid: latestActId,
          queue,
          offset: 0,
          first: 5,
        };

        if (shouldShadow) {
          getData(
            API.getMatchWinHistoryV2(getMatchWinHistoryPayload),
            noopModel,
            undefined,
            { headers, expiryTime: getExpiryTime() },
          );
        }

        return Promise.all([
          getData(
            API.getMatchWinHistory(getMatchWinHistoryPayload),
            ValorantPlayerMatchWinHistoryModel,
            ["val", "playerMatchWinHistory", profile.puuid, latestActId, queue],
            { headers, expiryTime: getExpiryTime() },
          ),
        ]);
      });
    }) ?? [];

  promises.push(...playerProfiles);

  return Promise.all(promises);
};

export default fetchLivePageData;
